<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco abstracts dei Libri</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='20' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters'
                           filterDisplay='menu'
                           :loading='loading'
                           responsiveLayout='scroll'
                           :globalFilterFields="['slug']"
                           >
                    <template #header>
                        <ListHeader
                            v-model="filters['global'].value"
                            @clear='clearFilter()'
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        >
                            <template #after-left>
                                <Button type='button' title='Scarica Associazioni Skills'
                                        class='p-button-warning mb-2 ml-2' @click='downloadMatches()'
                                >
                                    <font-awesome-icon :icon='["fas", "award"]' :style='{fontSize: "17px"}' class='mr-2' /> Scarica Associazioni Skills
                                </Button>
                            </template>
                        </ListHeader>
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='slug' header='Slug' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.slug }}
                        </template>
                        <template #filter='{filterModel}'>
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Slug"/>
                        </template>
                    </Column>

                    <Column field='original_title' header='Titolo' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.original_title }}
                        </template>
                    </Column>

                    <Column field='s_cats' header='Categorie' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.s_cats }}
                        </template>
                    </Column>

                    <Column field='s_aut' header='Autori' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.s_aut }}
                        </template>
                    </Column>

                    <Column field='last_update' header='Ultima modifica' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ unix2date(slotProps.data.last_update) }}
                        </template>
                    </Column>

                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='pointer ml-2'
                            title='Apri in nuovo Tab'
                            :style='{color: colors.bg.HELP}'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-color pointer ml-2'
                            title='Apri nello stesso Tab'
                            :style='{color: colors.bg.INFO}'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        />
                        </template>
                        <template #body='slotProps'>
                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>
                        </template>
                    </Column>
                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Cont/Books');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import ListHeader from '../../../components/4books/ListHeader';
import tools from '../../../libs/tools';

export default {
    components: {
        ListHeader
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'downloadSkillsAssociations']),
        downloadMatches(){
            this.downloadSkillsAssociations().then(res => {
                tools.download(res, 'associazioni.csv', 'text/csv;charset=utf-8;');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initFilters() {
            this.initFiltersByAllFields({});
            this.addFiltersByStartWith('slug');
        },
        clearFilter() {
            this.initFilters();
        },
    },
    mixins: [
        Notifications,
        Navigations,
    ],
};
</script>
